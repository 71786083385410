export const Categories = [
  "Industrials",
  "Health Care",
  "Information Technology",
  "Communication Services",
  "Consumer Discretionary",
  "Utilities",
  "Financials",
  "Materials",
  "Real Estate",
  "Consumer Staples",
  "Energy",
];

export const apiURL = "https://api.project-capitalizer.com/api/v1/";
